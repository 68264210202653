import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import '../assets/css/style.scss';

const Feedback = ({ thumbs_up, thumbs_down }) => {
    return (
        <div className="chatbot-feedback">
            <div className="feedback-filler">
            </div>
            <div className="feedback-container">
                <span>Did this answer your question? </span>
                <div className="thumbs_up_down_container">
                    <button onClick={thumbs_up} className="thumbs_up_down">
                        <FontAwesomeIcon icon={faThumbsUp} />
                    </button>
                    <button onClick={thumbs_down} className="thumbs_up_down thumb_down">
                        <FontAwesomeIcon icon={faThumbsDown} />
                    </button>
                </div>
            </div>
            <div className="small-filler">
            </div>
            
        </div>
    );
}

export default Feedback;