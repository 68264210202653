/**Project based on the Minimizable web chat UI template https://github.com/microsoft/BotFramework-WebChat/tree/master/samples/06.recomposing-ui/a.minimizable-web-chat
 * 
 * This is a wrapper for the WebChat component that allows it to:
 *  -Minimize/Maximize
 *  -Switch sides (Left-right)
 *  -Reset conversation
 *  -Alert new message status (Subtle red dot)
 *  -Alert bot connection status (Message icon filled/empty)
 *  -Display reaction buttons (Thumbs up/down) that sends event to copilot to send feedback
 * 
 * This chat interface is connected to the Production Student Chabot in Copilot Studios: Felix
 * 
 * Most of the Icons are from FontAwesome, however there are a few Microsoft icons. The License for this template, including the icons is included in the project solution
 */

// Imports from REACT
import classNames from 'classnames';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import ReactDOM, { createPortal } from 'react-dom';
// Imports from Bot Framework
import { createStore, createStyleSet } from 'botframework-webchat';
import { disconnect } from 'botframework-webchat-core';
// Custom React components
import WebChat from './WebChat';
import Feedback from './FeedbackPrompt';

//Services Imports
import { setVH } from '../services/ResizeChatBox';
import { shouldIgnore } from '../services/ignoreService';
import changePlaceholder from '../services/placeholderChange'
import styleSendBox from '../services/forcedStyles'
// Imports from Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faWindowMinimize, faExchangeAlt} from '@fortawesome/free-solid-svg-icons';
// CSS Styling
import '../assets/css/fabric-icons-inline.css';
import '../assets/css/style.scss';



const MinimizableWebChat = (props) => {
    /*
Function to get the connection to our Copilot bot token.
Sets the State variable: token
*/
    const handleFetchToken = useCallback(async () => {

        //CURRENTLY CONECTED TO TEST 
        const res = await fetch(process.env.REACT_APP_BOT_URL, { method: 'GET' });
        const { token } = await res.json();
        setToken(token);
    }, []);

     

    //This defines the Redux store used to later set the state variable: store. Contains actions for Start conversations, receive messages and disconnecting the conversation
    const storeMiddleware = useCallback(({ dispatch }) => next => action => {
      
        if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
            dispatch(
                {
                    type: 'WEB_CHAT/SEND_EVENT',
                    payload: {
                        name: 'startConversation',
                        type: 'event'
                    }
                }
            );
        } else if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
          // console.log(`ACTION SCHEMA: \n ${JSON.stringify(action, null, 2)}`);
            const activity = action.payload.activity;

            if (activity.from.role === 'bot' && (activity.type === 'message' || activity.name === 'DynamicPlanFinished')) {
                //console.log(`Role is: ${activity.from.role}, Set new message is true. Activity Type: ${activity.type} `);
                setNewMessage(true);
                //console.log(`Last message from the bot: ${activity.text}`);
                //console.log(`Should be ignored? ${shouldIgnore(activity.text)}`);
                if (!shouldIgnore(activity.text)) {
                    getLastMsgRef();
                    setCanProvideFeedback(true);
                }
                storeBotResponses(activity.text);
                //&& activity.type === 'message'
            } else {
                //console.log(`Role is: ${activity.from.role}, Set new message is false. Activity Type: ${activity.type} `);
                setNewMessage(false);
                setCanProvideFeedback(false);
                activity.name === 'InputFeedback' ? changePlaceholder(true) : changePlaceholder(false);
            }
        } else if (action.type === 'DIRECT_LINE/DISCONNECT_FULFILLED') {
            setStore(null);
        }   

        return next(action);
    }, []);

    //State variables used for the minimize-maximize, switch sides and reset conversation effects
    const [loaded, setLoaded] = useState(false);
    const [minimized, setMinimized] = useState(true);
    const [newMessage, setNewMessage] = useState(false);
    const [side, setSide] = useState('right');
    const [token, setToken] = useState();
    const [botResponses, setBotResponses] = useState([]);
    const [directLine, setDirectLine] = useState();
    const [store, setStore] = useState(createStore({}, storeMiddleware));
    const [canProvideFeedback, setCanProvideFeedback] = useState(false);
    const [lastMsgRef, setLastMsgRef] = useState();

    //Sets the vh variable to calculate height when the chatbox is maximazed
    useEffect(() => {
        if (loaded) {
            setTimeout(() => {
                setVH();
                //styleSendBox();
            }, 500);
        }
        window.addEventListener('resize', setVH);
        
        return () => {
            window.removeEventListener('resize', setVH);
        };
    }, [token]);

    //We get the reference of the last bot message to place the feedback mechanism in
    const getLastMsgRef = () => {
        setTimeout(() => {
            setLastMsgRef(() => {
                const messagesList = document.querySelectorAll('.webchat__basic-transcript__activity-body');
                const newRef = messagesList[messagesList.length - 1];
                return newRef;
            });
        }, 1000);
    }
    //Sets the background transparent so the website can be seen behind the non-Chatbot-occupyied parts
    const styleSet = useMemo(
        () => createStyleSet({
            backgroundColor: 'Transparent'
        }),
        []
    );

    // Event handler methods
    const storeBotResponses = (newMsg) => {
        if (newMsg !== undefined) { 
            setBotResponses(prevResponses => {
                const updatedResponses = prevResponses.concat(newMsg);
                //console.log(updatedResponses);
                return updatedResponses;
            });
        }
    }

    /**This handler resets the conversation completely and errases the messages*/
    const handleResetButtonClick = useCallback(() => {
        setToken(null);
        setDirectLine(null);
        store.dispatch(disconnect());
        setStore(createStore({}, storeMiddleware));
        handleFetchToken();
    }, [store]);

    const handleMaximizeButtonClick = useCallback(async () => {
        setLoaded(true);
        setMinimized(false);
        setNewMessage(false);
    }, []);

    const handleMinimizeButtonClick = useCallback(() => {
        setMinimized(true);
        setNewMessage(false);
    }, []);

    const handleSwitchButtonClick = useCallback(() => {
        setSide(side === 'left' ? 'right' : 'left');

    }, [side]);

    //Sends a user feedback event on thumbsup/down-button click
    const sendGoodFeedback = useCallback(() => {
        store.dispatch({
            type: 'WEB_CHAT/SEND_EVENT',
            payload: {
                name: 'SendFeedback',
                type: 'event',
                value: `Good: ${botResponses[botResponses.length-1]}`
            }
        });
    });
    const sendBadFeedback = useCallback(() => {
        store.dispatch({
            type: 'WEB_CHAT/SEND_EVENT',
            payload: {
                name: 'SendFeedback',
                type: 'event',
                value: `Bad: ${botResponses[botResponses.length - 1]}`
            }
        });
    });

    return (
        <div className="minimizable-web-chat">
            {minimized && (
                <button className="maximize" onClick={handleMaximizeButtonClick} aria-label="Help" title="Help">
                    <div>
                    <span className={token ? 'ms-Icon ms-Icon--MessageFill' : 'ms-Icon ms-Icon--Message'} />
                    {newMessage && <span className="ms-Icon ms-Icon--CircleShapeSolid red-dot" />}
                        <span className="call-to-action">Help</span>
                    </div>
                </button>
            )}
            {loaded && (
                <div id="chatbox" className={classNames(side === 'left' ? 'chat-box left' : 'chat-box right', minimized ? 'hide' : '')}>
                    <header>
                        <span className="branding">The OC Virtual Assistant</span>
                        <div className="filler" />
                        <button aria-label="Reset Conversation" title="Reset" className="reset" onClick={handleResetButtonClick}>
                            <FontAwesomeIcon icon={faSync} />
                        </button>
                        <button aria-label="Switch Side" className="switch" title="Switch" onClick={handleSwitchButtonClick}>
                            <FontAwesomeIcon icon={faExchangeAlt} />
                        </button>
                        <button aria-label="Minimize Chat Screen" className="minimize" title="Minimize" onClick={handleMinimizeButtonClick}>
                            <FontAwesomeIcon icon={faWindowMinimize} />
                        </button>
                    </header>
                    <WebChat
                        className="react-web-chat"
                        onFetchToken={handleFetchToken}
                        store={store}
                        styleSet={styleSet}
                        token={token}
                    />
                    {/*  TODO: Try to put the Feedback above the sendbox */}
                    {canProvideFeedback &&
                        lastMsgRef &&
                        ReactDOM.createPortal(
                            <Feedback
                                thumbs_up={sendGoodFeedback}
                                thumbs_down={sendBadFeedback}
                            />,
                            lastMsgRef
                        )}
                    <footer >
                        <div className="filler" />
                        <p className="disclaimer">*The OC Virtual Assistant uses generative AI technology in its responses.
                            For more information, please visit <br /> <a aria-label="Link to The OC Virtual Asistant page" href="https://www.okanagan.bc.ca/ocvirtualassistant" target="_blank"><strong>OC-Virtual-Assistant</strong></a></p>
                        <span className="policies">
                            <a aria-label="Link to The OC Privacy Policy page" href="https://www.okanagan.bc.ca/sites/default/files/2023-04/Privacy_Policy.pdf" target="_blank">Privacy</a> | <a aria-label="Link to The OC Accesibility Policy page" target="_blank" href="https://www.okanagan.bc.ca/web-accessibility-and-usability">Accessibility</a>
                        </span>
                    </footer>
                </div>
            )}
        </div>
    );
};

export default MinimizableWebChat;


