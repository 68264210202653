 const styleSendBox = () => {
    const sendBox = document.getElementsByClassName('webchat__send-box__main')[0];
     if (sendBox) {
         const marginAmount = '15px';
         console.log('rounding the borders');
         sendBox.style.borderRadius = '10px';
         sendBox.style.marginBottom = marginAmount;
         sendBox.style.marginRight = marginAmount;
         sendBox.style.marginLeft = marginAmount;
     } else {
         console.log('No sendbox reference yet');
     }
};

export default styleSendBox;